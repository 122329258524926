<template>
  <div>
     <button
      type="button"
      class="btn modal-btn"
     @click="openImageFileModal"
    >
      <i class="fa fa-picture-o mr-2" aria-hidden="true"></i>
      Choose Image
    </button>

     <ImageFileModal  :selectedImage="selectedImageRoot" @selectedImageCallbackModal="selectedImage($event)" ref="ImageFileCompRef"/>
  </div>
</template>

<script>
import ImageFileModal from "./ImageFileModal.vue"
export default {
    props:["selectedImageRoot"],
    components:{
        ImageFileModal
    },
data(){
    return{
        
    }
},
methods:{
    openImageFileModal(){
         this.$refs.ImageFileCompRef.openModalFunc()
    },
    selectedImage(e){
        this.$emit("selectedImageCallback",e)
    }
}
}
</script>

<style scoped>
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}
</style>